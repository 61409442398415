/**
 * Create timeline animation for connectors between the Big Number scenes.
 * Here you'll find connector line animation and scene stack transition along z axis.
 * @param previousSlideSelector
 * @param nextSlideSelector
 */
function createBigNumberConnector(previousSlideSelector, nextSlideSelector) {

    const CONNECTOR_ANIMATION_TIME = 1;

    let svgSelector = `svg:not(.intro__draw-line):not(.connector-line)`;

    let previousSlideSvgElement       = document.querySelector(`${previousSlideSelector}-hidden ${svgSelector}`);
    let nextSlideSvgElement           = document.querySelector(`${nextSlideSelector}-hidden ${svgSelector}`);
    let sceneWrapperElement           = document.querySelector(`.scene-wrapper`);
    let nextSlideConnectorElement     = document.querySelector(`${nextSlideSelector}-connector `);
    let nextSlideConnectorSvgElement     = document.querySelector(`${nextSlideSelector}-connector svg`);
    let nextSlideConnectorLineElement = document.querySelector(`${nextSlideSelector}-connector line`);
    let connectorTimeline             = new TimelineLite();
    let nextSlideSelectorIdx          = stackedScenes.indexOf(nextSlideSelector);

    connectorTimeline.to(stackedScenes, CONNECTOR_ANIMATION_TIME, {z: '-=300', ease: Power2.easeInOut}, 'lineStart');
    connectorTimeline.to('.connector-line', CONNECTOR_ANIMATION_TIME, {z: '-=300', ease: Power2.easeInOut}, 'lineStart');

    if (nextSlideSelectorIdx <= stackedScenes.length - 1 && nextSlideSelectorIdx >= 0) {
        let connectorlLineDrawProgress = {value: 0};

        function updateBox() {
            let previousSvgRect         = previousSlideSvgElement.getBoundingClientRect();
            let nextSlideSvgElementRect = nextSlideSvgElement.getBoundingClientRect();
            let sceneElementRect = sceneWrapperElement.getBoundingClientRect();

            let tangens = previousSvgRect.width / 300;
            let degs    = Math.atan(tangens) * 180 / Math.PI;
            TweenLite.set(nextSlideConnectorElement, {
                rotationX: `90deg`,
            });

            nextSlideConnectorSvgElement.style.transform = `skewX(-${degs}deg)`;
            nextSlideConnectorElement.style.top       = `${nextSlideSvgElementRect.top + nextSlideSvgElementRect.height - sceneElementRect.top}px`;
            nextSlideConnectorElement.style.left      = `${nextSlideSvgElementRect.left - sceneElementRect.left}px`;

        }

        let lastUpdate = 0;

        function updateLine() {
            let newUpdate = Math.ceil(connectorTimeline.progress() - 0.1);
            if(lastUpdate !== newUpdate) {
                lastUpdate = newUpdate;
                setScrollPercent(masterTimeline.progress());
            }

            TweenLite.set(nextSlideConnectorLineElement, {
                strokeDashoffset: 300 + 300 * connectorlLineDrawProgress.value
            });
        }

        function repositionElements () {
            updateBox();
            updateLine();
            setTimeout(function () {
                updateBox();
                updateLine();
            }, 250)
        }
        repositionElements();
        window.addEventListener('resize', repositionElements);

        connectorTimeline.to(connectorlLineDrawProgress, CONNECTOR_ANIMATION_TIME, {
            value: 1,
            ease: Power2.easeInOut,
            onUpdate: updateLine
        }, 'lineStart');

    }

    return connectorTimeline;
}
