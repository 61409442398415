
function createSideNavOut() {
    let sideNavTimeline = new TimelineLite();

    sideNavTimeline.to(".navbar-side .active", .5, {
        opacity: 0,
        scale: .5
    });

    sideNavTimeline.staggerTo(".navbar-side li:not(.active)", .15, {
        yPercent: "-100%",
        opacity: 0,
        ease: Back.easeOut.config(3)
    }, .1, "-=.3");

    sideNavTimeline.to(".navbar-side-wrapper", .001, {
        zIndex: -100
    });

    sideNavTimeline.timeScale(1);

    return sideNavTimeline
}