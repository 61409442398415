function __createSceneOutAutoTimeline(selector) {
    let sceneTextTimeline = new TimelineLite();
    let potentialBigNumber = [`${selector} .big-number__first-digit`, `${selector} .big-number__second-digit`, `${selector} .big-number__times-symbol`];
    let bigNumber = [];

    potentialBigNumber.forEach(_selector => {
        let bigNumberElement = document.querySelector(_selector);
        if(bigNumberElement)
            bigNumber.push(bigNumberElement);
    });

    sceneTextTimeline.addLabel(`disappear`, `0`);
    sceneTextTimeline.addLabel(`bigNumber`, `disappear`);

    /**
     * For now svg undrawing is disabled, hence this commented stuff
     */
    /*let svg = document.querySelector(`${selector} svg:not(.connector-line)`);

    if (svg) {
           sceneTextTimeline.add(vivusOut(svg.id, 1), "lineOut");
           sceneTextTimeline.to(svg, .01, {opacity: 0}, `lineOut+=.99`)
    }

    */

    sceneTextTimeline.to(`${selector} .scene-header`, .8, {
        yPercent: `-110%`,
        ease: Back.easeIn.config(2)
    }, `disappear`);

    sceneTextTimeline.to(`${selector} .mobile-nav-toggle`, .2, {
        opacity: 0
    }, "-=.2");

    sceneTextTimeline.to(`${selector} .scene-text`, .7, {
        xPercent: `-130%`,
        ease: Back.easeIn.config(1.5)
    }, `disappear+=.1`);

    sceneTextTimeline.to(`${selector} .big-number-info__text`, .6, {
        yPercent: `120`,
        ease: Back.easeIn.config(2)
    }, `bigNumber+=.3`);


    let bigNumberPercentElement = document.querySelector(`${selector} .big-number-info__percent`);
    if(bigNumberPercentElement) {
        sceneTextTimeline.to(bigNumberPercentElement, .6, {
            opacity: 0,
            yPercent: `130%`,
            ease: Back.easeIn.config(2)
        }, `-=.5`);
    }
    sceneTextTimeline.staggerTo(bigNumber, .6, {
        y: window.innerHeight / 2,
        ease: Back.easeIn.config(1)
    }, .2, `bigNumber`);
    sceneTextTimeline.to(`${selector}`, 0, {zIndex: 0});
    sceneTextTimeline.timeScale(.75);

    return sceneTextTimeline;
}

/**
 * Create scene disappear animation, add autoTimeline as asynchronous tween, and add some dead zone around async tween to avoid firing it.
 *
 * @param selector
 * @param sceneAutoTimeline
 * @param textAnimationLength
 * @private
 */
function __createSceneOutTimeline(selector, sceneAutoTimeline, textAnimationLength) {
    let sceneGraphicsTimeline = new TimelineLite();
    sceneGraphicsTimeline.addLabel(`disappear`, 0);

    /**
     * Wee need to have some area for doing nothing. Otherwise scrolling via navbar causes animation to hide content without scrolling.
     * For scene out animation the deadZone is added at the start.
     * @type {number}
     */
    let deadZoneAroundTimeline = 0;
    sceneGraphicsTimeline.add(sceneAutoTimeline, `disappear+=${deadZoneAroundTimeline}`);
    return sceneGraphicsTimeline;
}

/**
 * Create Big number appear animation.
 * It will also serve as disappear animation when user scrolls in reverse.
 *
 * @param {string} selector
 */
function createBigNumberSceneOut(selector) {
    /**
     * Define how "long" is the area on the main timeline which is reserved for asynchronous timeline.
     * Although this defines duration of the timeline IT WILL NOT BE TRANSLATED INTO TIME DIRECTLY!!!
     * Instead, this is mapped to the animation "length" on the scrollbar.
     *
     * @type {number}
     */
    const TEXT_ANIMATION_LENGTH = 2;
    let sceneTextTimeline = __createSceneOutAutoTimeline(selector);
    return __createSceneOutTimeline(selector, sceneTextTimeline, TEXT_ANIMATION_LENGTH);
}
