// SCENES ANIMATIONS

let stackedScenes = [
    '#scene-efficiency',
    '#scene-mobility',
    '#scene-innovation',
    '#scene-growth',
    '#scene-collaboration',
];

/**
 * Create animation fired on timeline, but playing independently.
 * Keep track of the direction and reverse animation if master timeline changes direction.
 * If master timeline passes through start or end set asynchronous animation progress to 0 and 1 respectively.
 *
 * @param {TimelineLite} timeline
 * @param {number} length
 */
function createAsynchronousTween(timeline, length = 1) {

    let forwards = true;
    let lastValue = 0;
    let asyncProgress = {value: 0};

    return TweenLite.to(asyncProgress, length, {
        value: 1,
        onUpdate: function(){
            forwards = asyncProgress.value > lastValue;

            if(asyncProgress.value === 0) {
                timeline.progress(0).pause()
            } else if (asyncProgress.value === 1) {
                timeline.progress(1).pause()
            } else if(forwards) {
                timeline.play();
            } else {
                timeline.reverse();
            }

            lastValue = asyncProgress.value;
        }
    });

}