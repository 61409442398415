function createIntroSceneOut(selector) {

    let hiddenSvg                  = document.querySelector(selector + " .intro__text-hidden svg");
    let hiddenSvgLine              = document.querySelector(selector + " .intro__text-hidden svg polyline");
    let visibleSvgLine              = document.querySelector(selector + " .intro__text svg polyline");
    let visibleSvg              = document.querySelector(selector + " .intro__text svg");
    let hiddenHeader               = document.querySelector(selector + " .intro__text-hidden h1");
    let introDrawlineElements      = document.querySelectorAll(selector + " svg.intro__draw-line");
    let introTextElements          = document.querySelectorAll(selector + " .intro__text");
    let efficiencySvg              = document.querySelector("#scene-efficiency-hidden .svg-animation");
    let efficiencyDrawlineElements = document.querySelectorAll("#scene-efficiency svg.intro__draw-line");

    let introTimeline = new TimelineLite();

    let lineSvgBox;
    let lineBox;
    let svgBox;
    let fontSize;
    let lineLength;

    function updateBoxes() {
        lineSvgBox = hiddenSvg.getBoundingClientRect();
        lineBox    = hiddenSvgLine.getBoundingClientRect();
        svgBox     = efficiencySvg.getBoundingClientRect();
        fontSize   = getComputedStyle(hiddenHeader).fontSize;

        let height = svgBox.top - lineSvgBox.top + svgBox.height;
        lineLength = height + 950;
        visibleSvgLine.setAttribute('points', `0,0 0,${height} 950,${height}`);
        hiddenSvgLine.setAttribute('points', `0,0 0,${height} 950,${height}`);
        visibleSvg.style.strokeDasharray = lineLength
    }

    updateBoxes();

    introTimeline.addLabel(`disappear`, `0`);

    introTimeline.to(`${selector} .intro__partners-wrapper`, .8, {
        xPercent: 100,
        opacity: 0
    }, `disappear+=.5`);

    if (window.innerWidth >= 992) {
        $(".signup-widget").addClass("expanded");
        introTimeline.set(".signup-widget", {className: "-=expanded"}, "disappear+=1");
    }

    introTimeline.to(['.intro__image-ready', '.intro__image-man'], .5, {opacity: 0},'disappear');
    introTimeline.fromTo("svg.intro__draw-line polyline", 1,
        {
            css: {
                strokeWidth: window.innerWidth <= 768
                    ? `${(Math.ceil(parseFloat(fontSize.replace('px', '')) / 9))}px`
                    : `${(Math.ceil(parseFloat(fontSize.replace('px', '')) / 6))}px`
            },
        }, {
            css: {strokeWidth: window.innerWidth <= 768 ? '1px' : '2px'},
            ease: Power2.easeIn
        }, "disappear+=.8");

    let introTextFlyoutProgress = {value: 0};

    function updateLine() {
        TweenLite.set(introTextElements, {
            x: Math.round((-lineBox.width + svgBox.left + svgBox.width - lineSvgBox.left) * introTextFlyoutProgress.value),
        });
        TweenLite.set("svg.intro__draw-line", {
            css: {strokeDashoffset: (1 - introTextFlyoutProgress.value) * lineLength}
        });
        if (introTextFlyoutProgress.value === 1) {
            TweenLite.set(introDrawlineElements, {
                opacity: 0
            });
            TweenLite.set(efficiencyDrawlineElements, {
                opacity: 1
            });
        } else {
            TweenLite.set(introDrawlineElements, {
                opacity: 1
            });
            TweenLite.set(efficiencyDrawlineElements, {
                opacity: 0
            });
        }
    }

    let introTextFlyout = TweenLite.to(introTextFlyoutProgress, 1.5, {
        value: 1,
        onUpdate: updateLine
    });


    window.addEventListener('resize', function () {
        updateBoxes();
        updateLine();
        setTimeout(function () {
            updateBoxes();
            updateLine();
        }, 250)
    });

    updateLine();

    introTimeline.to(introTextElements, 1.5, {scale: 1}, `disappear+=.4`);
    introTimeline.to(introTextElements, .5, {color: "white"}, `disappear+=1.4`);
    introTimeline.add(introTextFlyout, `disappear+=.4`);
    return introTimeline
}
