function createIntroSceneIn(selector) {
    let introTimeline = new TimelineLite();

    introTimeline.addLabel(`appear`, `0`);

    introTimeline.to(selector, 0, {opacity: 1, zIndex: 1}, `appear`);

    introTimeline.from(`${selector} .intro__text`, .4, {
        opacity: 0,
        ease: Power2.easeIn,
    });

    introTimeline.from(`${selector} .intro__image-man`, .3, {
        opacity: 0,
        ease: Power2.easeIn,
    });

    introTimeline.from(`${selector} .intro__image-ready`, .3, {
        opacity: 0,
        ease: Power2.easeIn,
    });

    return introTimeline;
}