// END CARD ANIMATION

function endCardSceneIn(selector) {

    let tl = new TimelineLite();
    tl.addLabel(`appear`, `0`);
    tl.addLabel(`inputAppear`, `appear+=.5`);

    tl.to(selector, 0, {opacity: 1}, `appear+=.01`);
    tl.from('#end-card-icon', .01, {zIndex: -1, opacity: 0}, `appear`);
    tl.add(vivusIn("end-card-icon", 1), `appear`);

    tl.to(`.signup-widget`, .5, {opacity: 0}, `appear`);
    if(window.innerWidth <= 992)
        tl.fromTo(`.scene-wrapper`, .5, {bottom: 34}, {bottom: 0}, `appear`);

    tl.from(`${selector} .info-header__image`, .4, {
        yPercent: -150,
        opacity: 0
    }, `appear`);

    tl.to(selector, 0, {zIndex: 1}, `appear+=.2`);

    tl.from(`${selector} .end-card-input-header`, .4, {
        yPercent: -150,
        opacity: 0
    }, `inputAppear`);

    tl.from(`${selector} .input-group`, .6, {
        width: 0,
        opacity: 0,
        ease: Expo.easeOut
    }, `inputAppear`);

    tl.timeScale(1);

    return tl;
}
