// INTRO ANIMATION

let vivuses = {};

function vivusIn(svgId, time) {
    let vivus       = vivuses[svgId] || (vivuses[svgId] = new Vivus(svgId));
    let progressObj = {value: 0};
    return TweenMax.fromTo(progressObj, time, {value: 0}, {
            value: 1,
            onUpdate: function () {
                vivus.setFrameProgress(progressObj.value)
            }
        }
    )
}

function vivusOut(svgId, time) {
    let vivus       = vivuses[svgId] || (vivuses[svgId] = new Vivus(svgId));
    let progressObj = {value: 1};
    return TweenMax.fromTo(progressObj, time, {value: 1}, {
            value: 0,
            onUpdate: function () {
                vivus.setFrameProgress(progressObj.value);
            }
        }
    )
}

// MASTER TIMELINE

let masterTimeline;


function initMasterTimeline() {
    masterTimeline = new TimelineMax({
        paused: true,
        onUpdate: function () {
            let currentLabel = masterTimeline.currentLabel();
            currentLabel     = currentLabel.replace('pre-', '');

            let targetLink = $(`[href=${currentLabel}]`);

            if (!targetLink.length) {
                $('a.active').removeClass('active');
            } else if (!targetLink.hasClass('active')) {
                $('a.active').removeClass('active');
                targetLink.addClass('active');
            }
        }
    });
    masterTimeline.timeScale(1.2);

    let introSceneOut = createIntroSceneOut('#scene-intro');

    masterTimeline.addLabel("pre-intro");
    masterTimeline.addPause().addLabel("intro");
    masterTimeline.add(introSceneOut, "intro");
    masterTimeline.set('.scene-wrapper', {className: "+=scene-wrapper--3d"});
    masterTimeline.add(createSideNavIn());

    masterTimeline.add(createBigNumberSceneIn('#scene-efficiency'), "pre-efficiency-=.4");
    masterTimeline.addPause().addLabel("efficiency");

    masterTimeline.add(createBigNumberSceneOut('#scene-efficiency'), "efficiency");

    masterTimeline.add(createBigNumberConnector('#scene-efficiency', '#scene-mobility'));
    masterTimeline.addLabel("pre-mobility");
    masterTimeline.add(createBigNumberSceneIn('#scene-mobility'), "pre-mobility");
    masterTimeline.addPause().addLabel("mobility");
    masterTimeline.add(createBigNumberSceneOut('#scene-mobility'), "mobility");

    masterTimeline.add(createBigNumberConnector('#scene-mobility', '#scene-innovation'));
    masterTimeline.addLabel("pre-innovation");
    masterTimeline.add(createBigNumberSceneIn('#scene-innovation'), "pre-innovation");
    masterTimeline.addPause().addLabel("innovation");
    masterTimeline.add(createBigNumberSceneOut('#scene-innovation'), "innovation");

    masterTimeline.add(createBigNumberConnector('#scene-innovation', '#scene-growth'));
    masterTimeline.addLabel("pre-growth");
    masterTimeline.add(createBigNumberSceneIn('#scene-growth'), "pre-growth");
    masterTimeline.addPause().addLabel("growth");
    masterTimeline.add(createBigNumberSceneOut('#scene-growth'), "growth");

    masterTimeline.add(createBigNumberConnector('#scene-growth', '#scene-collaboration'));
    masterTimeline.addLabel("pre-collaboration");
    masterTimeline.add(createBigNumberSceneIn('#scene-collaboration'), "pre-collaboration");
    masterTimeline.addPause().addLabel("collaboration");
    masterTimeline.add(createBigNumberSceneOut('#scene-collaboration'), "collaboration");

    masterTimeline.add(createBigNumberConnector('#scene-collaboration', '#scene-connected-world'));

    /**
     * Overlap connected world animation with previous big number out timeline
     */
    masterTimeline.addLabel("pre-connected-world", "-=1.25");

    masterTimeline.add(connectedWorldSceneIn('#scene-connected-world'), "pre-connected-world");
    masterTimeline.addPause().addLabel("connected-world");
    /**
     * Here is the delay between connected world and end-card-input scene.
     */
    masterTimeline.add(connectedWorldSceneOut('#scene-connected-world'), "connected-world");

    /**
     * Overlap end card scene with previous scene out animation, to avoid white
     * screen.
     */
    masterTimeline.addLabel("pre-end-card", "-=1.2");
    masterTimeline.add(createSideNavOut(), "pre-end-card-=.5");
    masterTimeline.add(endCardSceneIn('#scene-end-card'), "pre-end-card");
    masterTimeline.addPause().addLabel("end-card");

    masterTimeline.fromTo(`.globalclientfooter`, .5, {
            zIndex: -1,
            yPercent: 100
        },
        {
            zIndex: 99,
            yPercent: 0
        });

    masterTimeline.addPause().addLabel("footer-reveal");

}

function getScrollPercent() {
    let h       = document.documentElement,
        b       = document.body,
        st      = 'scrollTop',
        sh      = 'scrollHeight';
    let percent = (h[st] || b[st]) / ((h[sh] || b[sh]) - h.clientHeight);
    return percent > 0 ? percent < 1 ? percent : 1 : 0;
}

function setScrollPercent(percent) {
    let h            = document.documentElement,
        b            = document.body,
        sh           = 'scrollHeight';
    let scrollHeight = (h[sh] || b[sh]);
    window.scrollTo(0, percent * (scrollHeight - h.clientHeight));
}

let skipProgressUpdate = false;

function initScrollHandler() {
    window.addEventListener('scroll', function (e) {
        if (skipProgressUpdate)
            return;
        TweenLite.killTweensOf(masterTimeline);
        TweenLite.to(masterTimeline, .2, {
            progress: getScrollPercent()
        });
    });
}

$('[data-goto-label]').click(function (e) {
    e.preventDefault();

    let currentLabel = getNearestLabel().name;
    let targetLabel  = $(this).attr('href');
    if (currentLabel === targetLabel)
        return false;

    let nearestPre = null;
    let lastPre    = null;

    let targetLabelObj  = null;
    let currentLabelObj = null;
    let labelsArray     = masterTimeline.getLabelsArray();

    if (masterTimeline.getLabelTime(currentLabel) > masterTimeline.getLabelTime(targetLabel)) {
        labelsArray = labelsArray.reverse();
    }

    labelsArray.forEach(label => {
        if (!currentLabelObj) {
            if (label.name === currentLabel)
                currentLabelObj = label;
        } else {
            if (!nearestPre) {
                if (label.name.indexOf('pre-') === 0) {
                    nearestPre = label;
                }
            }

            if (!targetLabelObj) {
                if (label.name.indexOf('pre-') === 0) {
                    lastPre = label;
                }
            }

            if (label.name === targetLabel) {
                targetLabelObj = label
            }
        }
    });

    skipProgressUpdate = true;
    TweenLite.killTweensOf(masterTimeline);
    if (nearestPre && lastPre) {
        TweenLite.to(masterTimeline, 2, {
            ease: Power1.easeIn,
            time: nearestPre.time,
            onUpdate: function () {
            },
            onComplete: function () {
                masterTimeline.time(lastPre.time);
                setTimeout(function () {
                    setScrollPercent(masterTimeline.progress());
                    TweenLite.to(masterTimeline, 2, {
                        ease: Power1.easeOut,
                        time: masterTimeline.getLabelTime(targetLabel),
                        onUpdate: function () {
                        },
                        onComplete: function () {
                            setTimeout(function () {
                                setScrollPercent(masterTimeline.progress());
                                skipProgressUpdate = false
                            }, 50)
                        }
                    });

                }, 50);

            }
        });
    } else {
        TweenLite.to(masterTimeline, 3, {
            ease: Power1.easeInOut,
            time: masterTimeline.getLabelTime(targetLabel),
            onUpdate: function () {

            },
            onComplete: function () {
                setTimeout(function () {
                    setScrollPercent(masterTimeline.progress());
                    skipProgressUpdate = false
                }, 50)
            }
        });
    }

});

function getNearestLabel() {
    let closestLabel = null;
    let closestLabelDistance;
    let labelsArray  = masterTimeline.getLabelsArray();

    labelsArray.filter(label => label.name.indexOf('pre-') === -1).forEach(label => {
        if (!closestLabel || Math.abs(masterTimeline.time() - label.time) < closestLabelDistance) {
            closestLabel         = label;
            closestLabelDistance = Math.abs(masterTimeline.time() - label.time);
        }
    });

    return closestLabel
}

function navigateToNextSlide(backwards = false) {
    if (skipProgressUpdate)
        return;
    let labelsArray     = masterTimeline.getLabelsArray();
    let currentLabel    = getNearestLabel().name;
    let currentLabelObj = null;

    let targetLabelObj;
    if (backwards)
        labelsArray = labelsArray.reverse();
    labelsArray.filter(label => label.name.indexOf('pre-') === -1).forEach(label => {
        if (!currentLabelObj) {
            if (label.name === currentLabel)
                currentLabelObj = label;
        } else {
            if (!targetLabelObj) {
                targetLabelObj = label;
            }
        }
    });

    if (!targetLabelObj)
        targetLabelObj = currentLabelObj;

    skipProgressUpdate = true;
    TweenLite.killTweensOf(masterTimeline);
    let transitionTime = Math.abs(targetLabelObj.time - masterTimeline.time());
    TweenLite.to(masterTimeline, transitionTime, {
        ease: Power0.easeInOut,
        time: targetLabelObj.time,
        onUpdate: function () {
        },
        onComplete: function () {
            setScrollPercent(masterTimeline.progress());
            skipProgressUpdate = false;
        }
    });
}

$(document).keydown(function (evt) {
    if (evt.keyCode === 32 && !evt.shiftKey || evt.keyCode === 34 || evt.keyCode === 40) {
        navigateToNextSlide();
        evt.preventDefault();
    } else if (evt.keyCode === 32 && evt.shiftKey || evt.keyCode === 33 || evt.keyCode === 38) {
        navigateToNextSlide(true);
        evt.preventDefault();
    }
});

$(document).ready(function () {
    $('body').css('padding-bottom', window.innerHeight * 40);
    initMasterTimeline();
    $('.scene-wrapper').css('opacity', '1');
    $('.globalclientfooter').css('opacity', '1');
    $('.signup-widget').css('transform', 'translateX(0)');

    initScrollHandler();
    if ('scrollRestoration' in history) {
        history.scrollRestoration = "manual";
    } else {
        window.scrollTo(0, 0);
        masterTimeline.progress(0);
    }
});

// VIDEO CAROUSEL
$(window).on("load", function () {
    $('.video-carousel').slick({
        speed: 300,
        slidesToShow: 2,
        arrows: true,
        infinite: false,
        responsive: [
            {
                breakpoint: 992,
                settings: {
                    slidesToShow: 1
                }
            },
        ]
    });

    $('.scrollable').on('afterChange', function (event, slick, currentSlide) {

        if (currentSlide === 2) {
            $('.slick-next').addClass('hidden');
        } else {
            $('.slick-next').removeClass('hidden');
        }

        if (currentSlide === 0) {
            $('.slick-prev').addClass('hidden');
        } else {
            $('.slick-prev').removeClass('hidden');
        }
    });

    $('.carousel-slide').attr('tabindex', '-1');
    $('.slick-current').attr('tabindex', '-1');
    $('.close-button').attr('tabindex', '-1');
});
$(document).ready(function () {
    MktoForms2.loadForm("https://app-ab10.marketo.com", "324-BZD-350", 1209, function () {
        $(".mktoEmailField").attr('placeholder', 'email').attr('tabindex', '-1');
        $(".mktoButton").html('').attr('tabindex', '-1');
    });

    MktoForms2.loadForm("https://app-ab10.marketo.com", "324-BZD-350", 1210, function () {
        $(".mktoEmailField").attr('placeholder', 'email').attr('tabindex', '-1');
        $(".mktoButton").html('').attr('tabindex', '-1');
    });
});

let $status       = $('.slide-count');
let $slickElement = $('.video-carousel');

window.addEventListener((/Firefox/i.test(navigator.userAgent)) ? "DOMMouseScroll" : "mousewheel", function (e) {

    if($(e.target).parents('.globalclientfooter').length > 0)
        return;

    e.preventDefault();
    if ((e.deltaY || -e.wheelDelta || e.detail) > 0)
        navigateToNextSlide();
    else
        navigateToNextSlide(true);

    return false;
});

if ("ontouchstart" in window) {
    let touchStart;
    document.body.addEventListener('touchstart', function (e) {
        if($(e.target).parents('.globalclientfooter').length > 0)
            return;

        touchStart = e.touches[0].clientY;
    }, {passive: false});

    document.body.addEventListener('touchmove', function (e) {
        if($(e.target).parents('.globalclientfooter').length > 0)
            return;

        let touchEnd = e.changedTouches[0].clientY;
        e.preventDefault();

        if (touchStart > touchEnd + 100) {
            navigateToNextSlide();
        } else if (touchStart < touchEnd - 100) {
            navigateToNextSlide(true);
        }
    }, {passive: false});

    document.body.addEventListener('touchend', function (e) {
        if($(e.target).parents('.globalclientfooter').length > 0)
            return;

    }, {passive: false});
}


$slickElement.on('init reInit afterChange', function (event, slick, currentSlide, nextSlide) {
    let i = (currentSlide ? currentSlide : 0) + 1;
    $status.text(`${i}/${slick.slideCount - slick.options.slidesToShow + 1}`);
});

// MOBILE SIDE NAVIGATION

$(".mobile-nav-toggle").click(function () {
    $(".navbar-side-wrapper").addClass("active");
});

$(".navbar-side__item").click(function () {
    $(".navbar-side-wrapper").removeClass("active");
});

$(".navbar-side__close-button").click(function () {
    $(".navbar-side-wrapper").removeClass("active");
});

// VIDEO POPUP

$(".carousel-slide").click(function () {
    $(".video-popup-wrapper").addClass("visible");
    let url                  = $(this).attr('data-video-url');
    let youtubeWrapper       = document.querySelector('.youtube-embed-container');
    youtubeWrapper.innerHTML = `<iframe src='${url}' frameborder='0' allowfullscreen allow="autoplay"></iframe>`;
    $(".scene").addClass("video-popup-open");
    $(".navbar-side").addClass("video-popup-open");
});

$(".close-button").click(function () {
    $(".video-popup-wrapper").removeClass("visible");
    $(".scene").removeClass("video-popup-open");
    $(".navbar-side").removeClass("video-popup-open");
    let youtubeWrapper       = document.querySelector('.youtube-embed-container');
    youtubeWrapper.innerHTML = "";
});

// SIGNUP WIDGET

$(`.signup-widget__handle`).click(function () {
    let $signup = $('.signup-widget');
    if ($signup.hasClass('expanded')) {
        $signup.removeClass('expanded');
    } else {
        $signup.addClass('expanded');
    }
});

MktoForms2.whenReady(function (form) {

    //Add an onSuccess handler

    form.onSuccess(function (values, followUpUrl) {

        //get the form's jQuery element and hide it

        $('.signup-form-message').css("display", "block");
        $('.signup-form').hide();
        $('.widget-header--expanded').hide();
        $('.end-card-input-header').hide();

        let tl = new TimelineLite();
        tl.to('.signup-widget', 0.5, {
            xPercent: '100%',
            ease: Power2.easeOut,
        }, 2.5);
        tl.to('.scene-wrapper', 0.5, {
            bottom: 0,
            ease: Power2.easeOut,
            onComplete: function () {
                $('.scene-wrapper').addClass('scene-wrapper--full-page')
            }
        }, 2.5);
        //return false to prevent the submission handler from taking the lead to the follow up url.
        return false;

    });

});

if (window.innerWidth < 992) {
    $(document).on('focus', 'input', function () {
        skipProgressUpdate = true;
    });

    $(document).on('blur', 'input', function () {
        skipProgressUpdate = false;
    });
}
