function __createSceneInAutoTimeline(selector) {
    let sceneAutoTimeline = new TimelineLite();

    let potentialBigNumber = [`${selector} .big-number__first-digit`, `${selector} .big-number__second-digit`, `${selector} .big-number__times-symbol`];
    let bigNumber = [];

    potentialBigNumber.forEach(_selector => {
        let bigNumberElement = document.querySelector(_selector);
        if(bigNumberElement)
            bigNumber.push(bigNumberElement);
    });
    sceneAutoTimeline.addLabel(`appear`, 0);
    sceneAutoTimeline.addLabel(`bigNumber`, `appear`);

    sceneAutoTimeline.from(`${selector} .scene-header`, .8, {
        yPercent: `-110%`,
        ease: Back.easeOut.config(2)
    }, `appear`);


    // Draw svg image with vivus.
    let svg = document.querySelector(`${selector} svg:not(.connector-line)`);
    if (svg) {
        sceneAutoTimeline.add(vivusIn(svg.id, 1), `appear`);
        sceneAutoTimeline.from(svg, .01, {opacity: 0}, `appear`)
    }

    sceneAutoTimeline.from(`${selector} .mobile-nav-toggle`, .5, {
        opacity: 0
    }, "-=.5");

    sceneAutoTimeline.from(`${selector} .scene-text`, .7, {
        xPercent: `-130%`,
        ease: Back.easeOut.config(1.5)
    }, `appear+=.1`);

    sceneAutoTimeline.staggerFrom(bigNumber, .6, {
        y: window.innerHeight / 2,
        ease: Back.easeOut.config(1)
    }, .2, `bigNumber`);

    sceneAutoTimeline.from(`${selector} .big-number-info__text`, .6, {
        yPercent: `140%`,
        ease: Back.easeOut.config(2)
    }, `bigNumber+=.3`);

    let bigNumberPercentElement = document.querySelector(`${selector} .big-number-info__percent`);
    if(bigNumberPercentElement) {
        sceneAutoTimeline.from(bigNumberPercentElement, .6, {
            opacity: 0,
            yPercent: `130%`,
            ease: Back.easeOut.config(2)
        }, `bigNumber+=.4`);
    }

    sceneAutoTimeline.timeScale(.75);
    return sceneAutoTimeline;
}

/**
 * Create scene appear animation, add autoTimeline as asynchronous tween, and add some dead zone around async tween to avoid firing it.
 *
 * @param selector
 * @param sceneAutoTimeline
 * @param textAnimationLength
 * @private
 */
function __createSceneInTimeline(selector, sceneAutoTimeline, textAnimationLength) {
    let sceneGraphicsTimeline = new TimelineLite();

    sceneGraphicsTimeline.addLabel(`appear`, 0);
    sceneGraphicsTimeline.to(selector, 0, {zIndex: 1}, `appear+=.01`);

    /**
     * Wee need to have some area for doing nothing. Otherwise scrolling via navbar causes animation to hide content without scrolling.
     * For scene in animation the deadZone is added at the end.
     * @type {number}
     */
    let deadZoneAroundTimeline = 0;
    sceneGraphicsTimeline.add(sceneAutoTimeline, 'appear');
    sceneGraphicsTimeline.set({}, {}, `+=${deadZoneAroundTimeline}`);
    return sceneGraphicsTimeline;
}

/**
 * Create Big number disappear animation.
 * It will also be used as appear animation when user scrolls backwards.
 *
 * @param {string} selector
 */
function createBigNumberSceneIn(selector) {
    /**
     * Define how "long" is the area on the main timeline which is reserved for asynchronous timeline.
     * Although this defines duration of the timeline IT WILL NOT BE TRANSLATED INTO TIME DIRECTLY!!!
     * Instead, this is mapped to the animation "length" on the scrollbar.
     *
     * @type {number}
     */
    const TEXT_ANIMATION_LENGTH = 2;

    let sceneAutoTimeline = __createSceneInAutoTimeline(selector);
    return __createSceneInTimeline(selector, sceneAutoTimeline, TEXT_ANIMATION_LENGTH);
}

