

function connectedWorldSceneOut(selector) {

    let tl = new TimelineLite();

    tl.addLabel(`appear`, `0`);

    tl.to(`${selector} .scene-header-wrapper`, 1.2, {
        z: -12000,
        ease: Power2.easeIn,
    }, `appear`);

    tl.to(`${selector} .partner-info-wrapper`, 1.2, {
        z: -5000,
        ease: Power2.easeIn,
    }, `appear`);

    tl.to(`${selector} .scene__video-wrapper`, 1.2, {
        z: -7000,
        ease: Power2.easeIn,
    }, `appear`);

    /**
     * Take the scenes stack out with the connected world scene
     */
    tl.to(stackedScenes, 1, {z: '-=5000', ease: Power2.easeInOut}, 'appear');
    tl.to(`${selector}`, 0, {opacity: 0, zIndex: 0});
    tl.timeScale(.5);

    return tl;
}
