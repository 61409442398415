function createSubIntroSceneIn(selector) {
    let introTimeline = new TimelineLite();

    introTimeline.addLabel(`disappear`, `0`);
    introTimeline.addLabel(`appear`, `disappear+=.8`);

    introTimeline.to(`.scroll-indicator`, .5, {opacity: 0}, `disappear`);

    if (window.innerWidth >= 992) {
        $(".signup-widget").addClass("expanded");
        introTimeline.set(".signup-widget", {className: "-=expanded"});
    }

    introTimeline.to(`${selector} .intro__image-ready`, .7, {
        //opacity: 0,
        scale: 11,
        yPercent: 25,
        ease: Power2.easeIn,
    }, `disappear`);

    introTimeline.to(`${selector} .intro__image-man`, 1, {
        //opacity: 0,
        scale: 6,
        ease: Power2.easeIn,
    }, `disappear`);

    introTimeline.to(`${selector} .intro__text`, 1.3, {
        scale: 1.7,
        ease: Power2.easeIn
    }, `disappear`);

    introTimeline.to(`${selector} .intro__image`, .8, {
        scale: 3,
        opacity: 0
    }, `appear`);

    introTimeline.from(`${selector} .partners-header`, .8, {
        opacity: 0
    }, "appear+=.7");

    introTimeline.from(`${selector} .partner-logo`, .3, {
        zIndex: -1,
        opacity: 0,
        yPercent: 100,
        ease: Back.easeOut.config(2)
    }, "appear+=.7");

    return introTimeline;
}