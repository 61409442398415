
// CONNECTED WORLD ANIMATION

function connectedWorldSceneIn(selector) {
    let tl = new TimelineLite();


    tl.addLabel(`start-appear`, `0`);
    tl.addLabel(`appear`, `start-appear+=1.2`);

    tl.to(selector, 0, {zIndex: 1}, `start-appear+=.01`);

    tl.from(`${selector} .scene-header-wrapper`, .9, {
        z: 299,
        ease: Power2.easeOut,
    }, `appear-=.9`);

    tl.from(`${selector} .partner-info-wrapper`, 1.2, {
        z: 299,
        ease: Power2.easeOut,
    }, `appear-=1.2`);

    tl.from(`${selector} .scene__video-wrapper`, 1.0, {
        z: 299,
        ease: Power2.easeOut,
    }, `appear-=1.0`);


    return tl;
}